import { useRouter } from './../util/router'
import { apiRequest } from './../util/util'
import { useEffect } from 'react';
import { useAuth } from '../util/auth';
import CleveLoading from '../components/CleveLoading';
import { Typography, Box } from '@material-ui/core';
import LinkMui from "@material-ui/core/Link";
import { Link } from "./../util/router";
import analytics from '../util/analytics';

const LinkedInCallback = () => {
  const router = useRouter();
  const auth = useAuth()
  const { code, state } = router.query;

  const fetchAccessToken = async (code, state) => {
    console.log('fetching')
    try {
      analytics.track("connectedLinkedin")
      const data = await apiRequest("linkedin-callback", "POST", { code, state });
      // router.push('/onboarding?step=2')
      router.push('/')
    } catch (error) {
      console.error(error)
      // router.push('/onboarding?step=2&error=Failed%20to%20connect%20LinkedIn')
      router.push('/')
    }
    
  }

  useEffect(() => {

    if (code && auth?.user) {
      fetchAccessToken(code, state)
    }
    else if (auth?.user && !(code && state)) {
      // router.push('/onboarding?step=2&error=Failed%20to%20connect%20LinkedIn')
      // toast.error('Failed to connect LinkedIn')
      router.push('/')
    }
  }, [code, state, auth.user]);

  return (
    <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <CleveLoading message="Connecting your LinkedIn..." />
      <Box mt={2}>
      <Typography>This takes too long?&nbsp;
        <LinkMui component={Link} to='/onboarding?step=2'>
          Click here to skip
        </LinkMui>
      </Typography>
      </Box>
    </Box>
  );
};

export default LinkedInCallback;