"use client";
import Chat from "../components/Chat";
import { Box } from "@material-ui/core";
import { useChat } from "ai/react";
import React, { useState, useEffect }from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth";
import { createMessage, usePagedThreads, useMessagesByThread, fetchPagedMessages } from "../util/db";


const QUESTIONS = [
  "Tell me about a time you overcame an obstacle.",
  "How do you manage stress?",
  "What have been your most positive and negative management experiences?",
  "What is your greatest achievement?",
  "What's your biggest weakness?",
  "What is your biggest pet peeve?",
  "How do you define success?",
  "When have you failed? What did you learn from this experience?",
  "What is your occupation?",
  "Who is your audience?",
  "What kind of content do you want to make?",
  "What is your expertise?",
  "What platform is this content for?"
];

const getRandomQuestion = () => {
  const random = Math.floor(Math.random() * QUESTIONS.length);
  return QUESTIONS[random]
}

function ChatPage(props) {
  document.title = "Chat - Cleve";
  const auth = useAuth();
  const [userName, setUserName] = useState(null);
  const [threads, setThreads] = useState(null);
  const [question, setQuestion] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [response, setResponse] = useState(false);
  const [threadMessages, setThreadMessages] = useState({});
  const [token, setToken] = useState(null);

  const onFinish = (message) => { 
    console.log('onFinish')
    setResponse(message)
  }

  // {data, fetchNextPage, hasNextPage, isLoading, isError}
  const pagedThreads = usePagedThreads(auth.user.id)

  //console.log(pagedThreads.fetchNextPage())

  const getAndSetToken = async () => {
    if (auth.user) {
      const idToken = await auth.user.auth.currentUser.getIdToken(true); // Force refresh
      setToken(idToken);
      return idToken;
    }
    return null;
  };

  useEffect(() => {
    getAndSetToken();
  }, [auth.user]);

  const { messages, input, append, handleInputChange, handleSubmit, isLoading, setMessages } = useChat({
    onFinish,
    ...(auth.user && token && {
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: {
        user: auth.user
      }
    })
  });
  
  const handleMessageSubmit = async (event) => {
    if (!input.trim()) return;
    
    event.preventDefault();
    
    // Refresh token before sending message
    const freshToken = await getAndSetToken();
    if (!freshToken) return;

    const userId = auth.user.id;
    let newThreadId = threadId
    if(question) {
      // Simplify message creation logic and fix promise handling.
      let questionMessage = {
        role: 'assistant',
        content: question,
        contentType: 'text',
      };
      
      console.log({userId, threadId, questionMessage});
      let message = await createMessage(userId, null, questionMessage);
      console.log(message.threadId);
      newThreadId = message.threadId
      setThreadId(message.threadId);
      
      setMessages((prevData) => prevData ? [...prevData, questionMessage] : [questionMessage]);

      
      //append(userMessage); // Uncommented to use append from useChat.
      setQuestion(null)
    }

    let userMessage = {
      role: 'user',
      content: input,
      contentType: 'text',
    };
    let message = await createMessage(userId, newThreadId, userMessage);
    setThreadId(message.threadId);
    //console.log({userId, message.threadId, userMessage});
    handleSubmit(event); // No need to pass headers here anymore
  };

  
  const handleQuestion = () => {
    setQuestion(getRandomQuestion())
  }

  useEffect(() => {
    if (auth.user && pagedThreads.data){
      const fetchThreadMessages = async () => {
        let messages = [];
        
        // Assuming pagedThreads is an array of objects, each with a 'threads' array
        for (const page of pagedThreads.data.pages) {
          for (const thread of page.threads) {
            // Here, you simulate the `useMessagesByThread` logic within useEffect
            // Replace this with your actual data fetching logic
            const {messages: threadMessages} = await fetchPagedMessages(thread.id, {})();
            thread.messages = threadMessages
            messages.push(thread);
          }
        }
  
        setThreads(messages);
      };
  
      fetchThreadMessages();
      //setThreads(pagedThreads.data)
      //console.log(auth.user)
      if (messages.length < 1){
        setMessages([
          {'role': 'assistant', 'content': `Hello ${auth.user.displayName}! I'm Cleve, your personal content assistant. I will help you make personalized content by asking you several questions. Feel free to click the 🔃 icon to change the question.`}
        ])
      }
    }
  }, [pagedThreads.data, auth]);

  useEffect(() => {
    // this will only be called when data has changed
    // run your code here
    const saveMessage = async () => {
        //let lastMessage = messages[messages.length - 1];
        let { threadId: newThreadId } = await createMessage(auth.user.id, threadId, response);
        setThreadId(newThreadId)
        setResponse(null)
    }
    if(response){
      saveMessage();
    }
  }, [response]);

  return (
    <Box style={{backgroundColor: '#f3f3f3'}}>
      <Meta title="Chat with Cleve" />
      <Chat
          isMobile={props.isMobile}
          question={question}
          setQuestion={setQuestion}
          handleQuestion={handleQuestion}
          input={input}
          handleInputChange={handleInputChange}
          handleMessageSubmit={handleMessageSubmit}
          messages={messages}
          threads={threads}
          isLoading={isLoading}
        />
    </Box>
  );
}

export default requireAuth(ChatPage);