import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Box,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  makeStyles,
  Input,
  TextField,
  InputAdornment,
  Tooltip,
  InputBase,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  SvgIcon,
  DialogContentText
} from '@material-ui/core';
import { useDarkMode } from "./../util/theme";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteIconOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CalendarIcon from '@material-ui/icons/InsertInvitationOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EditIcon from '@material-ui/icons/EditOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import FilterMenu from './FilterMenu';
import SortMenu from './SortMenu';
import { usePagedPostsByOwner, deletePost, toTimestamp, likePost, unlikePost, updatePost, updateUser } from '../util/db';
import { useAuth } from '../util/auth';
import PostEditor from './PostEditor';
import { useSnackbar } from './SnackbarContext';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { POST_TYPE } from '../util/enums';
import analytics from '../util/analytics';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useRouter } from '../util/router';
import AddIcon from '@material-ui/icons/Add';
import TagManagementDialog from './TagManagementDialog';
import { textToHtmlParagraphs } from '../util/util';
import ScheduleDialog from './ScheduleDialog';
import { apiRequest } from '../util/util';
import { Clock24Regular, Edit24Regular, Copy24Regular, CalendarClock24Regular, Heart24Regular, Heart24Filled, Note24Regular, Sparkle24Filled } from '@fluentui/react-icons';

dayjs.extend(customParseFormat);

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    overflow: 'visible'
  },
  cardActions: {
    // You can define styles for the clickable area if necessary
    padding: 0,
    justifyContent: 'flex-start',
  },
  content: {
    paddingTop: theme.spacing(4),
    position: 'relative',
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  noteContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: '20px',
    maxHeight: '160px', /* Adjust based on your requirement */
    transition: 'max-height 0.3s ease',
    whiteSpace: 'pre-wrap',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 50,
      left: 0,
      right: 0,
      height: '70%',
      background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`, /* Adjust the color to match your background */
      PointerEvent: 'none', /* Prevents the pseudo-element from being clickable */
    },
  },
  
  expanded: {
    maxHeight: 'none',

    '&::before': {
      display: 'none' /* Hide the fading effect when expanded */
    },
  },

  seeMoreButton: {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    opacity: 0.7,
    width: '100%',
    '&:hover':{backgroundColor: 'transparent'}
  },
  date: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: 'gray',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    marginRight: theme.spacing(1),
    zIndex: 1
  },
  optionsButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 2, // ensure the button is above other elements
  },
  copyButton: {
    position: 'absolute',
    right: '16px',
    bottom: '16px',
    color: '#444'
  },
  editButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    color: '#444'
  },
  textField: {
    marginRight: 'auto',
    width: 200,
    //color: theme.palette.text.secondary,
  },
}));

const Post = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null); // Post with options menu open
  const [currentPost, setCurrentPost] = useState(null); // Post currently edited
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});
  const auth = useAuth();
  const router = useRouter();
  const queryClient = useQueryClient();

  const {note} = props

  const toggleNoteExpansion = (noteId) => {
    setExpandedNotes((currentExpandedNotes) => ({
      ...currentExpandedNotes,
      [noteId]: !currentExpandedNotes[noteId]
    }));
  };

  const handleScheduleDialogOpen = (note) => {
    setSelectedPost(note);
    const date = note.scheduledAt ? note.scheduledAt.toDate() : new Date();
    var tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, 16);
    setSelectedDateTime(localISOTime);
    setScheduleDialogOpen(true);
  };

  const handleScheduleDialogClose = () => {
    setSelectedPost(null);
    setScheduleDialogOpen(false);
  };


  const updateTags = async (tags) => {
    //check if any of the tag is new
    const globalTags = auth.user?.tags || []
    const newTags = tags.filter(tag => !globalTags.includes(tag))
    if(newTags.length > 0){
      await updateUser(auth.user.id, {tags: [...new Set([...globalTags, ...tags])]})
    }
    await updatePost({id: selectedPost.id, tags: tags})
    queryClient.invalidateQueries({queryKey: ['posts', auth.user.id]})
    toast.success('Tags updated')
  }

  //let { data, fetchNextPage, hasNextPage, isLoading, isError } = usePagedPostsByOwner(auth?.user?.id)

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Feedback mechanism (e.g., toast notification) can be implemented here
      console.log('Text copied to clipboard');
    });
    toast.success('Text copied to clipboard');
    analytics.track('copyPost')
  };

  const handleClick = (event, note) => {
    setAnchorEl(event.currentTarget);
    setSelectedPost(note);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shouldTruncate = (note) => {
    return note.content.length > 200;
  }

  const handleDelete = async () => {
    // Implement delete functionality
    console.log('Delete note with id:', selectedPost.id);
    await deletePost(selectedPost.id);
    queryClient.invalidateQueries('posts');
    handleClose();
    toast.success('Post deleted');
  };

  const handleGenerateCarousel = (post) => {
    let params = new URLSearchParams();
    if(auth.user){
      params.append('avatar', auth.user.photoURL)
      params.append('name', auth.user.displayName)
      params.append('handle', '@' +  auth.user.email.split('@')[0])
    }
    params.append('prompt', post.content);
    window.open(`https://carousel.cleve.ai?${params.toString()}`, '_blank');
  }

  const handleEdit = (post) => {
    // Implement card click functionality
    analytics.track('clickEditPost', {postId: post.id})
    console.log('Card clicked:', post.id);
    post.content = textToHtmlParagraphs(post.content)
    setCurrentPost(post);
    handleClose();
  };

  const handleChipClick = (tag) => {
    // Implement chip click functionality
    const searchParams = new URLSearchParams(router.location.search);

    searchParams.set('tags', tag);

    const newSearchString = searchParams.toString();

    if (router.location.search !== `?${newSearchString}`) {
      console.log('pushed')
      router.push(`${router.pathname}?${newSearchString}`);
    }
  };

  const handleLike = (post) => {
    if(post.liked){
      analytics.track('dislikePost')
      unlikePost(post.id)
    } else{
      likePost(post.id)
      analytics.track('likePost')
      toast.success('Like noted! Cleve is learning your style and preferences')
    }
    queryClient.invalidateQueries('posts');
  }

  return (
    <>
        <Card key={note.id} className={classes.card}>
          { currentPost?.id == note.id ? <Tooltip title="Highligh to edit"><PostEditor currentNote={note} setCurrentNote={setCurrentPost} /></Tooltip> 
          : 
            <CardContent className={classes.content}>
              <Typography variant="body2" component="p" className={classes.date}>
                {new Date(note.createdAt.seconds * 1000).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })} - {POST_TYPE[note.type]?.label || note.type}
              </Typography>
              <IconButton
                aria-label="more"
                aria-controls="note-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the card click event
                  handleClick(e, note);
                }}
                className={classes.optionsButton}
              >
                <MoreVertIcon />
              </IconButton>
              <Typography
                className={`${classes.noteContent} ${expandedNotes[note.id] || !shouldTruncate(note) ? classes.expanded : ''}`}
                variant="body1"
                component="pre"
                dangerouslySetInnerHTML={{ __html: note.content }}
              />
              {shouldTruncate(note) &&
                <Button 
                  disableRipple
                  className={classes.seeMoreButton}
                  variant="text" color="default" onClick={() => toggleNoteExpansion(note.id)}>
                  <Typography style={{fontWeight: 'bold'}}>{expandedNotes[note.id] ? '...see less' : '...see more'}</Typography>
                </Button>
              }
              <div className={classes.tags}>
                <Tooltip title={note.status === 'Scheduled'? `Scheduled for ${dayjs(note.scheduledAt.toDate()).format('DD MMM - hh:mm A')}` : ''}>
                  <Chip
                        key={'status'}
                        label={note.status}
                        color={note.status === 'Scheduled' ? 'primary' : 'default'}
                        className={classes.chip}
                      />
                </Tooltip>
                {note.tags && note.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    variant='outlined'
                    label={tag}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents the card click event
                      handleChipClick(tag);
                    }}
                    className={classes.chip}
                  />
                ))}
                <Chip
                        key={'addTag'}
                        label="Tag"
                        color='default'
                        variant='outlined'
                        onClick={() => {setSelectedPost(note); setTagDialogOpen(true)}}
                        onDelete={() => {setSelectedPost(note); setTagDialogOpen(true)}}
                        deleteIcon={<AddIcon/>}
                        className={classes.chip}
                      />
              </div>
              <CardActions disableSpacing className={classes.cardActions}>
                <Tooltip title="Liked post will help Cleve learn your preferred writing style">
                  <IconButton aria-label="add to favorites" onClick={() => handleLike(note)}>
                    {/* {note.liked? <FavoriteIcon style={{color: '#EC2439'}} /> : <FavoriteIconOutlined /> } */}
                    <SvgIcon>
                      {note.liked? <Heart24Filled style={{color: '#EC2439'}}/> : <Heart24Regular/> }
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Post">
                  <IconButton onClick={() => handleEdit(note)} aria-label="edit">
                  <SvgIcon component={Edit24Regular}/>
                  </IconButton>
                </Tooltip>
                { router.location.pathname === '/posts' &&
                <Tooltip title="View Note">
                  <IconButton onClick={() => router.push('/notes/'+note.noteId)} aria-label="edit">
                    <SvgIcon component={Note24Regular}/>
                  </IconButton>
                </Tooltip>
                }
                <Tooltip title="Schedule Post">
                  <IconButton onClick={() => handleScheduleDialogOpen(note)} aria-label="edit">
                    <SvgIcon component={Clock24Regular}/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                  <IconButton onClick={() => copyToClipboard(note.content)} aria-label="copy">
                    <SvgIcon component={Copy24Regular}/>
                  </IconButton>
                </Tooltip>
                {note.scheduledAt && 
                <Typography variant="caption" component="p" style={{ marginLeft: 'auto', textAlign: 'right', color: 'gray',  padding: '8px 0px 0px 0px' }}>
                  Scheduled for {dayjs(note.scheduledAt.toDate()).format('DD MMM HH:mm')}
                </Typography>
                }
              </CardActions>
              
            </CardContent>
          }
        </Card>
      <ScheduleDialog open={scheduleDialogOpen} onClose={handleScheduleDialogClose} note={selectedPost} />
      <TagManagementDialog 
        isOpen={tagDialogOpen} 
        handleClose={() => {setSelectedPost(null); setTagDialogOpen(false)}} 
        selectedTags={selectedPost?.tags || []}
        updateTags={updateTags}
      />
      <Menu
        id="note-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEdit(selectedPost)}>
          <EditIcon fontSize="small" style={{ marginRight: 8 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleGenerateCarousel(selectedPost)}>
          <SvgIcon><Sparkle24Filled/></SvgIcon>
          Generate Carousel
        </MenuItem>
        <MenuItem onClick={handleDelete} style={auth?.user?.email === 'tia@cleve.ai' ? {position: 'fixed', top: 0, opacity:0.05} : {}}>
          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default Post;