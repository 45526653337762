import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid, Paper, Typography, InputBase, Chip, Tooltip, IconButton,
  Card, CardHeader, CardContent, CardActions, Avatar, CircularProgress, ButtonGroup,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link, Box, LinearProgress
} from '@material-ui/core';
import { PlayArrowOutlined, FavoriteBorder, OpenInNew, Loop, BookmarkBorder, CommentOutlined, ViewList, ViewCarousel, ViewAgenda, ViewHeadline, Videocam } from '@material-ui/icons';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import ThumbUp from '@material-ui/icons/ThumbUpOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router.js';
import { useCompletion } from "ai/react";
import * as Sentry from '@sentry/react';
import { Masonry } from "react-plock";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import analytics from '../util/analytics.js';
import { apiRequest } from "../util/util.js";
import { textToHtmlParagraphs } from '../util/util.js';
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const sampleN = (arr, n) => [...arr].sort(() => 0.5 - Math.random()).slice(0, n);

function removeDuplicateThumbnails(questions) {
  const seenArticles = new Set();
  console.log('Processing duplicate')

  let inspirations = questions
  if(questions.questions){
    inspirations = questions.questions
  }

  return inspirations.map(item => {
    if (item.news) {
      const articleId = item.news.articleId;
      console.log(articleId)
      if (seenArticles.has(articleId)) {
        console.log('Duplciate detected')
        // This is a duplicate, nullify the image_url
        return {
          ...item,
          news: {
            ...item.news,
            imageUrl: null
          }
        };
      } else {
        // This is the first occurrence, add to seen set
        seenArticles.add(articleId);
        console.log('Adding ', articleId)
        return item;
      }
    }
    // If there's no news, return the item as is
    return item;
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
    // backgroundColor: th,
  },
  searchBar: {
    // marginLeft: '50px',
    // marginRight: '50px',
    padding: '2px 8px',
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIcon: {
    padding: 3,
  },
  categoriesContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
  },
  cardContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(2),
    minWidth: 280,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
  },
  cardIcon: {
    color: '#2583E8',
    fontSize: '18px',
    marginRight: '6px'
  },
  newsCard: {
    backgroundColor: theme.palette.type === 'light'? '#EAF4FF' : '#0e122e',
    paddingTop: theme.spacing(4)
  },
  newsLabel:{
    color: '#2583E8',
    borderColor: '#2583E8',
    borderRadius: '5px',
    fontSize: '11px',
  },
  infoButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    
  },
  newsInfoIcon: {
    color: '#2583E8',
    padding: '2px'
  },
  personalCard: {
    backgroundColor: '#E8B724'
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
  },
  
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
  },
}));

const cardStyles = [
  { bgcolor: '#ffffff', color: '#000000', fontSize: '23px', fontFamily: 'Montserrat' },
  { bgcolor: '#ededed', color: '#000000', fontSize: '18px', fontFamily: 'Montserrat' },
  { bgcolor: '#fffaea', color: '#2d2d2d', fontSize: '31px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#ffffff', color: '#2d2d2d', fontSize: '19px', fontFamily: '"Courier New", monospace' },
  { bgcolor: '#eaf4ff', color: '#2d2d2d', fontSize: '35px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#e8eaf6', color: '#2d2d2d', fontSize: '21px', fontFamily: '"Montserrat", sans-serif' },
];

const nl2br = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  // Split by '. ', '! ', and '? ', but keep the delimiters
  // Exclude splits where the period is preceded by a number (e.g., "1. ", "2. ")
  return str.split(/(?<=[a-zA-Z]\. |\! |\? )/).map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br></br>
      <br></br>
      {/* {index !== str.split('\n').length - 1 && <br />} */}
      {/* {index !== str.split('\n').length - 1 && <br />} */}
    </React.Fragment>
  ));
};

const LinkedinCard = ({post, onClick }) => {
  const classes = useStyles()
  const [showImage, setShowImage] = useState(true);

  const avatar_url = post?.influencer?.avatar_url
  const name = post?.influencer?.name
  const profile_url = post?.influencer?.profile_url

  const reactions = ['applause_count', 'support_count', 'love_count', 'interest_count', 'laugh_count']
  let reactions_count = 0

  for (const reaction of reactions) {
    reactions_count += post?.[reaction] ?? 0
  }

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (post &&
    <Card elevation={0} className={classes.card} >
      <CardHeader
        avatar={
          <Avatar src={avatar_url} className={classes.avatar}>
            {!avatar_url && name && name.charAt(0)}
          </Avatar>
        }
        action={
          <Tooltip title="View post" >
            <IconButton 
              component={Link}
              href={post.external_linked_in_post_url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open post"
            >
              <OpenInNew style={{opacity: 0.5, fontSize: '18px'}} />
            </IconButton>
          </Tooltip>
        }
        title={name}
        subheader={formatDate(post?.created_at)}
        // subheaderTypographyProps={{ style: {fontSize: '12px'} }}
      />
      <CardContent className={classes.content} onClick={onClick}>
        <Typography variant="body" color="textPrimary" component="p" 
          dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(post?.text ?? '')}}>
        </Typography>
          {post.media_urls &&
          <img src={post.media_urls[0]} alt="Post image" className={classes.cardImage} onLoad={handleImageLoad} onError={() => setShowImage(false)} />
          }
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="likes">
        <img
            style={{ height: '18px', marginRight: '6px' }}
            src="/li-reactions.svg"
            alt="reactions-icon"
          />
          <Typography variant="body2" color="textSecondary">
          {reactions_count}
        </Typography>
        </IconButton>
        <div style={{marginLeft: 'auto'}}>
          <Typography variant="body2" color="textSecondary">
            {post.comments_count} comments • {post.reposts_count} reposts
          </Typography>
        </div>
        
      </CardActions>
    </Card>
  );
};

const TiktokCard = ({post, onClick }) => {
  const classes = useStyles();
  const [showImage, setShowImage] = useState(true);
  const [showAllText, setShowAllText] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);
  const [videoJobId, setVideoJobId] = useState(null);
  const [videoGenAlert, setVideoGenAlert] = useState({ open: false, message: '', severity: 'info' });
  const [imageError, setImageError] = useState(false);

  const avatar_url = post?.author?.avatar;
  const name = post?.author?.nickname || 'TikTok User';
  const hasMultipleImages = post?.images && Array.isArray(post.images) && post.images.length > 1;
  
  const currentImage = hasMultipleImages && post.images && post.images[currentImageIndex] 
    ? post.images[currentImageIndex] 
    : null;

  const getCombinedContent = () => {
    if (!hasMultipleImages || !post.images) return post.content;
    
    if (showDescription) {
      return post.images
        .map(img => img.description || '')
        .filter(text => text.trim().length > 0)
        .join('\n------\n');
    } else {
      return post.images
        .map(img => img.text || '')
        .filter(text => text.trim().length > 0)
        .join('\n------\n');
    }
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
    setImageError(false);
  };

  const handleImageError = () => {
    setShowImage(false);
    setImageError(true);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    if (hasMultipleImages) {
      setCurrentImageIndex((prev) => (prev + 1) % post.images.length);
    }
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    if (hasMultipleImages) {
      setCurrentImageIndex((prev) => (prev === 0 ? post.images.length - 1 : prev - 1));
    }
  };

  const toggleTextView = (e) => {
    e.stopPropagation();
    setShowAllText(!showAllText);
  };

  const toggleTextType = (e) => {
    e.stopPropagation();
    setShowDescription(!showDescription);
  };

  const formatCount = (count) => {
    if (!count && count !== 0) return '';
    if (count < 1000) return count;
    if (count < 1000000) return `${(count / 1000).toFixed(1)}K`;
    return `${(count / 1000000).toFixed(1)}M`;
  };

  const handleGenerateVideo = async (e) => {
    e.stopPropagation();
    
    try {
      setIsGeneratingVideo(true);
      
      let script = post.transcript || post.content;
      
      if (hasMultipleImages && post.images) {
        script = post.images
          .map(img => img.text || '')
          .filter(text => text.trim().length > 0)
          .join('\n\n');
      }
      
      if (!script || script.trim().length === 0) {
        throw new Error('No transcript or content available for video generation');
      }
      
      const imageUrl = post?.author?.avatar;
      console.log(imageUrl)
      
      if (!imageUrl) {
        throw new Error('No profile image available for avatar');
      }
      
      const response = await fetch('/api/generate-video', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          script,
          imageUrl,
          voice: "alloy"
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Server error: ${response.status}`);
      }
      
      const data = await response.json();
      
      setVideoJobId(data.jobId);
      
      setVideoGenAlert({
        open: true,
        message: 'Video generation started! You can check the status later using the job ID: ' + data.jobId,
        severity: 'success'
      });
      
    } catch (error) {
      console.error('Error generating video:', error);
      setVideoGenAlert({
        open: true,
        message: error.message || 'Failed to generate video. Please try again later.',
        severity: 'error'
      });
    } finally {
      setIsGeneratingVideo(false);
    }
  };
  
  const handleCloseAlert = () => {
    setVideoGenAlert({ ...videoGenAlert, open: false });
  };

  const renderContent = () => {
    if (!post || (!post.content && (!post.images || post.images.length === 0))) {
      return (
        <Typography variant="body2" color="textSecondary">
          No content available for this post.
        </Typography>
      );
    }
    
    if (!hasMultipleImages) {
      return (
        <Typography variant="body" color="textPrimary" component="p" 
          dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(post.content || '')}}>
        </Typography>
      );
    }
    
    if (!currentImage) {
      return (
        <Typography variant="body2" color="textSecondary">
          Could not load image data.
        </Typography>
      );
    }
    
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="caption" color="textSecondary">
            {currentImageIndex + 1} / {post.images.length}
          </Typography>
          <Box>
            <IconButton size="small" onClick={handlePrevImage}>
              <NavigateBefore />
            </IconButton>
            <IconButton size="small" onClick={handleNextImage}>
              <NavigateNext />
            </IconButton>
          </Box>
        </Box>
        
        {currentImage.url && (
          <Box mb={1} position="relative">
            <img 
              src={currentImage.url} 
              alt={`Image ${currentImageIndex + 1}`} 
              className={classes.cardImage} 
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
            {imageError && (
              <Box 
                bgcolor="background.paper" 
                p={2} 
                borderRadius={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={150}
              >
                <Typography variant="body2" color="textSecondary" align="center">
                  Image could not be loaded
                </Typography>
              </Box>
            )}
          </Box>
        )}
        
        {showAllText ? (
          <Typography variant="body" color="textPrimary" component="p" 
            dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(getCombinedContent() || 'No text content available')}}>
          </Typography>
        ) : (
          <Box>
            {showDescription ? (
              <Typography variant="body2" color="textPrimary">
                {currentImage.description || 'No description available'}
              </Typography>
            ) : (
              <Typography variant="body2" color="textPrimary">
                {currentImage.text || 'No text found in image'}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (post && 
    <Card elevation={0} className={classes.card}>
      <CardHeader
        avatar={
          <Avatar src={avatar_url} className={classes.avatar}>
            {!avatar_url && name ? name.charAt(0) : 'T'}
          </Avatar>
        }
        action={
          <Tooltip title="View post">
            <IconButton 
              component={Link}
              href={post.author?.unique_id && post.video_id ? 
                `https://www.tiktok.com/@${post.author.unique_id}/video/${post.video_id}` : 
                "https://www.tiktok.com"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open post"
            >
              <OpenInNew style={{opacity: 0.5, fontSize: '18px'}} />
            </IconButton>
          </Tooltip>
        }
        title={name}
        subheader={post.author?.unique_id ? '@' + post.author.unique_id : ''}
      />
      <CardContent className={classes.content} onClick={onClick}>
        {!hasMultipleImages && post.cover && (
          <img 
            src={post.cover} 
            alt="Post image" 
            className={classes.cardImage} 
            onLoad={handleImageLoad} 
            onError={handleImageError}
          />
        )}
        {renderContent()}
      </CardContent>
      <CardActions disableSpacing>
        <Box display="flex" alignItems="center">
          <IconButton aria-label="views" size="small">
            <PlayArrowOutlined className={classes.cardIcon} />
            <Typography variant="caption" color="textSecondary">
              {formatCount(post.play_count)}
            </Typography>
          </IconButton>
          
          <IconButton aria-label="likes" size="small">
            <FavoriteBorder className={classes.cardIcon} />
            <Typography variant="caption" color="textSecondary">
              {formatCount(post.digg_count)}
            </Typography>
          </IconButton>
          
          <IconButton aria-label="comments" size="small">
            <CommentOutlined className={classes.cardIcon} />
            <Typography variant="caption" color="textSecondary">
              {formatCount(post.comment_count)}
            </Typography>
          </IconButton>
          
          {/* <Tooltip title="Generate AI video from content">
            <IconButton 
              aria-label="generate video" 
              size="small" 
              onClick={handleGenerateVideo}
              disabled={isGeneratingVideo}
              color="primary"
            >
              <Videocam className={classes.cardIcon} />
              {isGeneratingVideo ? (
                <CircularProgress size={14} />
              ) : (
                <Typography variant="caption" color="primary">
                  AI Video
                </Typography>
              )}
            </IconButton>
          </Tooltip> */}
        </Box>
        
        <Box ml="auto" display="flex">
          {hasMultipleImages && (
            <>
              <Tooltip title={showAllText ? "Show current image text only" : "Show all texts"}>
                <IconButton 
                  size="small" 
                  onClick={toggleTextView}
                >
                  {showAllText ? <ViewAgenda fontSize="small" /> : <ViewHeadline fontSize="small" />}
                </IconButton>
              </Tooltip>
              
              <Tooltip title={showDescription ? "Show extracted text" : "Show image description"}>
                <IconButton 
                  size="small" 
                  onClick={toggleTextType}
                >
                  {showDescription ? <InfoIcon fontSize="small" /> : <TextFieldsIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </CardActions>
      
      <Snackbar 
        open={videoGenAlert.open} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseAlert} severity={videoGenAlert.severity}>
          {videoGenAlert.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

const TwitterCard = ({  avatar_url, name, handle, date, content, likes, shares, url, onClick }) => {
  const classes = useStyles()
  const [showImage, setShowImage] = useState(true);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Card elevation={0} className={classes.card} >
      <CardHeader
        avatar={
          <Avatar src={avatar_url} className={classes.avatar}>
            {!avatar_url && name && name.charAt(0)}
          </Avatar>
        }
        title={name}
        action={
          <Tooltip title="View post" >
            <IconButton 
              component={Link}
              href={url}
              target="_blank"
              color="inherit"
              rel="noopener noreferrer"
              aria-label="Open post"
            >
              <OpenInNew style={{opacity: 0.5, fontSize: '18px'}} />
            </IconButton>
          </Tooltip>
        }
        subheader={handle}
        subheaderTypographyProps={{ style: {fontSize: '12px'} }}
      />
      <CardContent className={classes.content} onClick={onClick}>
        <Typography variant="body" color="textPrimary"
          dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(content ?? '')}}
        >
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="likes">
          <FavoriteBorder className={classes.cardIcon} />
          <Typography variant="body2" color="textSecondary">
          {likes}
        </Typography>
        </IconButton>
        <IconButton aria-label="shares">
          <Loop className={classes.cardIcon} />
          <Typography variant="body2" color="textSecondary">
          {shares}
        </Typography>
        </IconButton>
        
      </CardActions>
    </Card>
  );
};

// Add this near other constants
const TIKTOK_LOADING_MESSAGES = [
  "Getting TikTok data...",
  "Processing videos...",
  "Analyzing viral content...",
  "Extracting wisdom from TikTok...",
  "Decoding social media trends...",
  "Teaching AI to understand TikTok slang...",
  "Converting dance moves to text...",
  "Identifying trending hashtags...",
  "Downloading creative inspiration...",
  "Finding the perfect sound bite...",
  "Examining engagement patterns...",
  "Translating visual stories to text...",
  "Scanning for viral potential...",
  "Almost there, just a few more seconds...",
  "This usually takes 1-2 minutes at most"
];

const InspirationDashboard = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState('Personal Branding');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [postType, setPostType] = useState(router.query.platform || 'twitter');
  const [linkedinProfileUrl, setLinkedinProfileUrl] = useState(router.query.linkedinProfile || '');
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [tiktokUsername, setTiktokUsername] = useState(router.query.tiktokProfile || '');
  const [isLoadingTikTokProfile, setIsLoadingTikTokProfile] = useState(false);
  const [isProfileView, setIsProfileView] = useState(
    router.query.linkedinProfile || router.query.tiktokProfile ? true : false
  );
  
  // Add this state to track when inputs are being edited by user
  const [isEditingLinkedinUrl, setIsEditingLinkedinUrl] = useState(false);
  const [isEditingTiktokUsername, setIsEditingTiktokUsername] = useState(false);
  
  // Add this state for loading messages
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  
  // Add new state for error handling
  const [fetchError, setFetchError] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);
  
  console.log(activeSearchTerm);

  const queryClient = useQueryClient();
  
  const updateUrlParams = (params) => {
    const currentParams = new URLSearchParams(window.location.search);
    
    const mode = currentParams.get('mode') || 'inspirations';
    
    const newParams = {
      mode,
      platform: postType,
      ...params
    };
    
    const queryString = Object.entries(newParams)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    
    router.replace(`/explore?${queryString}`, undefined, { shallow: true });
  };

  const handlePlatformChange = (type) => {
    setPostType(type);
    
    // Reset profile view when changing platforms
    setIsProfileView(false);
    
    // Only trigger default ideas fetch if not in profile view
    generateIdeasMutation.mutate(activeSearchTerm);
    
    updateUrlParams({ 
      platform: type,
      linkedinProfile: type === 'linkedin' ? linkedinProfileUrl : undefined,
      tiktokProfile: type === 'tiktok' ? tiktokUsername : undefined
    });
  };

  const handleLinkedinProfileSearch = (e) => {
    e.preventDefault();
    if (!linkedinProfileUrl) return;
    
    if (!linkedinProfileUrl.includes('linkedin.com/')) {
      alert('Please enter a valid LinkedIn profile URL');
      return;
    }
    
    setIsLoadingProfile(true);
    setIsEditingLinkedinUrl(false); // Reset editing state
    analytics.track('searchLinkedinProfile', { profileUrl: linkedinProfileUrl });
    fetchLinkedinProfileMutation.mutate(linkedinProfileUrl);
    
    updateUrlParams({ linkedinProfile: linkedinProfileUrl });
  };

  const handleTikTokProfileSearch = (e) => {
    if (e) e.preventDefault();
    if (!tiktokUsername) return;
    
    // Reset error state
    setFetchError(null);
    setIsLoadingTikTokProfile(true);
    setIsEditingTiktokUsername(false);
    analytics.track('searchTikTokProfile', { username: tiktokUsername });
    fetchTikTokProfileMutation.mutate(tiktokUsername);
    
    updateUrlParams({ tiktokProfile: tiktokUsername });
  };
  
  const { data: generatedIdeas = [], isFetching, error } = useQuery({
    queryKey: ['inspirations', postType, activeSearchTerm],
    queryFn: () => fetchIdeas(activeSearchTerm, auth?.user?.auth.currentUser.getIdToken()),
    enabled:  !!auth?.user,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 24000 * 60 * 60,
    initialData: () => {
      const cachedData = queryClient.getQueryData(['inspirations', postType, activeSearchTerm]);
      return cachedData || undefined;
    },
  });

  const generateIdeasMutation = useMutation({
    mutationFn: (keyword) => fetchIdeas(keyword, auth?.user?.auth.currentUser.getIdToken()),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['inspirations', postType, variables], data);
      setActiveSearchTerm(variables);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${postType}`, variables);
      }
    },
  });

  const fetchLinkedinProfileMutation = useMutation({
    mutationFn: async (profileUrl) => {
      const posts = await apiRequest('linkedin-fetch-posts', 'POST', {
        user: auth.user,
        linkedinUrl: profileUrl,
      });
      
      return posts;
    },
    onSuccess: (data) => {
      const formattedPosts = data.map(post => ({
        props: {
          post: {
            text: post.text || '',
            created_at: post.postedDate || new Date().toISOString(),
            external_linked_in_post_url: post.postUrl || '',
            comments_count: post.commentsCount || 0,
            reposts_count: post.repostsCount || 0,
            applause_count: post.likeCount || 0,
            support_count: post.empathyCount || 0,
            love_count: post.appreciationCount || 0,
            interest_count: post.InterestCount || 0,
            laugh_count: post.funnyCount || 0,
            media_urls: post.image ? [post.image[0]?.url] : [],
            influencer: {
              name: post.author?.firstName + ' ' + post.author?.lastName || 'LinkedIn User',
              avatar_url: post.author?.profilePicture || '',
              profile_url: post.author?.url || '',
            }
          }
        }
      }));
      
      queryClient.setQueryData(['inspirations', 'linkedin', 'profile'], formattedPosts);
      setPostType('linkedin');
      setActiveSearchTerm('profile');
      setIsProfileView(true);
      setIsLoadingProfile(false);
    },
    onError: (error) => {
      console.error('Error fetching LinkedIn profile:', error);
      setIsLoadingProfile(false);
      alert('Failed to fetch LinkedIn profile: ' + error.message);
    }
  });

  const fetchTikTokProfileMutation = useMutation({
    mutationFn: async (username) => {
      setFetchError(null); // Reset error state
      
      try {
        const posts = await apiRequest('tiktok-fetch-posts', 'POST', {
          user: auth.user,
          username: username,
          limit: 10
        });

        if (!posts || !Array.isArray(posts) || posts.length === 0) {
          throw new Error('No posts found for this username. Please check the username and try again.');
        }

        return posts;
      } catch (error) {
        // Check if API request has a specific error message
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(`API Error: ${error.response.data.message}`);
        }
        
        // Check if it's a network error
        if (error.message && error.message.includes('Network Error')) {
          throw new Error('Network error: Please check your internet connection and try again.');
        }
        
        // Default error message
        throw error;
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['inspirations', 'tiktok', 'profile'], data || []);
      setPostType('tiktok');
      setActiveSearchTerm('profile');
      setIsProfileView(true);
      setIsLoadingTikTokProfile(false);
      setFetchError(null);
    },
    onError: (error) => {
      console.error('Error fetching TikTok profile:', error);
      setIsLoadingTikTokProfile(false);
      setFetchError(error.message || 'Failed to fetch TikTok profile');
      
      // Don't show alert, we'll display the error in the UI
    }
  });

  const fetchIdeas = async (keyword, token) => {
    console.log("IT'S BEING CALLED ", keyword)
    const IdToken = await token
    const response = await fetch('/api/inspiration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${IdToken}`,
      },
      body: JSON.stringify({ 'type': postType, 'keyword': keyword }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch inspirations');
    }
  
    const data = await response.json();
    return data;
  }

  const handleCategoryToggle = (category) => {
    analytics.track('clickIdeaCategory', {category: category})
    setSearchTerm(category);
    const cachedData = queryClient.getQueryData(['inspirations', postType, category]);
    if (!cachedData) {
      generateIdeasMutation.mutate(category);
    } else {
      setActiveSearchTerm(category);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${postType}`, category);
      }
    }
  };

  const handleIdeaClick = (idea, author) => {
    const source = author ?? 'Unknown'
    analytics.track('clickInspiration', {idea: idea, postType: postType})
    let text = `
    <p>Inspired by this post:</p>
    <blockquote>
    ${textToHtmlParagraphs(idea)}
    <span>- ${source}</span>
    </blockquote>
    <p></p>
    `

    router.push(`/notes/new`, { note: { content: text, id: null } });
  };

  const handleSearchSubmit = (event) => {
    if (event) event.preventDefault();
    analytics.track('searchIdea', {searchTerm})
    if (generateIdeasMutation.isLoading) return;
    const cachedData = queryClient.getQueryData(['inspirations', postType, searchTerm]);
    if (!cachedData) {
      generateIdeasMutation.mutate(searchTerm);
    } else {
      setActiveSearchTerm(searchTerm);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${postType}`, searchTerm);
      }
    }
  };

  const handleRefresh = () => {
    if (activeSearchTerm) {
      generateIdeasMutation.mutate(activeSearchTerm);
    }
  };

  const handleRetry = () => {
    setIsRetrying(true);
    
    // Wait a moment before retrying to show the user something is happening
    setTimeout(() => {
      if (postType === 'tiktok' && tiktokUsername) {
        handleTikTokProfileSearch();
      } else if (postType === 'linkedin' && linkedinProfileUrl) {
        handleLinkedinProfileSearch();
      }
      setIsRetrying(false);
    }, 1000);
  };

  useEffect(() => {
    let _categories = {
      'linkedin': ['Personal Branding', 'Copywriting', 'Founders', 'Productivity', 'ChatGPT'],
      'twitter': ['coding','peace','thread','sales','viral','startup','fitness','nutrition','self-improvement', 'product-launch','relationships','money','online-money','work','milestone','marketing','founder','saas'],
      'tiktok': []
    }
    setCategories(shuffleArray(_categories[postType]).slice(0, 8))
    
    // Only fetch default ideas if we're not viewing a profile
    if (generatedIdeas.length < 1 && !isProfileView) {
      generateIdeasMutation.mutate(activeSearchTerm);
    }
  }, [postType, isProfileView]);

  useEffect(() => {
    if (!auth?.user) return;
    
    const { platform, linkedinProfile, tiktokProfile } = router.query;
    
    // Check if we're viewing a profile
    const profileView = Boolean(
      (platform === 'linkedin' && linkedinProfile) || 
      (platform === 'tiktok' && tiktokProfile)
    );
    
    setIsProfileView(profileView);
    
    if (platform && ['twitter', 'linkedin', 'tiktok'].includes(platform)) {
      setPostType(platform);
    }
    
    // For LinkedIn profile - only update if not currently editing
    if (linkedinProfile && platform === 'linkedin' && !isEditingLinkedinUrl) {
      if (linkedinProfileUrl !== linkedinProfile) {
        setLinkedinProfileUrl(linkedinProfile);
      }
      
      // Check if we have the profile data in cache already
      const cachedData = queryClient.getQueryData(['inspirations', 'linkedin', 'profile']);
      if (!cachedData) {
        console.log('Fetching LinkedIn profile from URL param:', linkedinProfile);
        fetchLinkedinProfileMutation.mutate(linkedinProfile);
      } else {
        // Still set the active search term
        setActiveSearchTerm('profile');
      }
    }
    
    // For TikTok profile - only update if not currently editing
    if (tiktokProfile && platform === 'tiktok' && !isEditingTiktokUsername) {
      if (tiktokUsername !== tiktokProfile) {
        setTiktokUsername(tiktokProfile);
      }
      
      // Check if we have the profile data in cache already
      const cachedData = queryClient.getQueryData(['inspirations', 'tiktok', 'profile']);
      if (!cachedData) {
        console.log('Fetching TikTok profile from URL param:', tiktokProfile);
        fetchTikTokProfileMutation.mutate(tiktokProfile);
      } else {
        // Still set the active search term
        setActiveSearchTerm('profile');
      }
    }
  }, [router.query, auth?.user, queryClient, linkedinProfileUrl, tiktokUsername, isEditingLinkedinUrl, isEditingTiktokUsername]);

  // Add this effect to cycle through loading messages
  useEffect(() => {
    let interval;
    
    if (isLoadingTikTokProfile) {
      interval = setInterval(() => {
        setLoadingMessageIndex(prevIndex => 
          (prevIndex + 1) % TIKTOK_LOADING_MESSAGES.length
        );
      }, 3000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoadingTikTokProfile]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={classes.root}>
      {postType === 'linkedin' && (
        <Paper component="form" className={classes.searchBar} onSubmit={handleLinkedinProfileSearch}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.input}
            placeholder="Enter LinkedIn profile URL (e.g., https://linkedin.com/in/username)"
            value={linkedinProfileUrl}
            onChange={(e) => {
              setIsEditingLinkedinUrl(true);
              setLinkedinProfileUrl(e.target.value);
            }}
            disabled={isLoadingProfile}
          />
          <Button 
            type="submit" 
            color="primary" 
            disabled={isLoadingProfile || !linkedinProfileUrl}
            size="small"
          >
            {isLoadingProfile ? <CircularProgress size={24} /> : 'Search'}
          </Button>
        </Paper>
      )}
    
      <div className={classes.categoriesContainer}>
        <div>
        {auth?.user && categories.map((category) => (
          <Chip
            key={category}
            label={category}
            disabled={generateIdeasMutation.isLoading}
            onClick={() => handleCategoryToggle(category)}
            color={category === activeSearchTerm ? "primary" : "default"}
            className={classes.chip}
          />
        ))}
        </div>
        <ButtonGroup style={{marginTop: '10px'}}>
          {
            ['twitter', 'linkedin', 'tiktok'].map((type) => (
              <Button 
                key={type}
                size="small"
                onClick={() => handlePlatformChange(type)}
                style={{opacity: postType === type? 1: 0.75, textTransform: 'capitalize'}}
                color={postType === type? 'secondary': 'default'}
                variant={postType === type? 'contained': 'outlined'}>
                {type}
              </Button>
            ))
          }
        </ButtonGroup>
      </div>
      
      {postType === 'tiktok' && (
        <Paper component="form" className={classes.searchBar} onSubmit={handleTikTokProfileSearch}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.input}
            placeholder="Enter TikTok username (e.g., aliabdaal)"
            value={tiktokUsername}
            onChange={(e) => {
              setIsEditingTiktokUsername(true);
              setTiktokUsername(e.target.value);
              // Clear error when user starts typing
              if (fetchError) setFetchError(null);
            }}
            disabled={isLoadingTikTokProfile}
            error={!!fetchError}
          />
          <Button 
            type="submit" 
            color="primary" 
            disabled={isLoadingTikTokProfile || !tiktokUsername}
            size="small"
          >
            {isLoadingTikTokProfile ? <CircularProgress size={24} /> : 'Search'}
          </Button>
        </Paper>
      )}
      
      {/* Display error message if there is one */}
      {fetchError && (
        <Box 
          mt={2}
          p={2}
          bgcolor="error.main"
          color="error.contrastText"
          borderRadius={1}
        >
          <Typography variant="body2">
            {fetchError}
          </Typography>
          <Box mt={1}>
            <Button 
              variant="outlined" 
              color="inherit" 
              size="small" 
              onClick={handleRetry}
              disabled={isRetrying}
              startIcon={isRetrying ? <CircularProgress size={16} color="inherit" /> : null}
            >
              {isRetrying ? 'Retrying...' : 'Retry'}
            </Button>
          </Box>
        </Box>
      )}
      
      {/* Add loading state */}
      {isLoadingTikTokProfile && !fetchError && (
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          p={4}
          height="50vh"
        >
          <img 
            src="/wrapped/working-cat2.gif" 
            alt="Loading" 
            style={{ width: '240px', marginBottom: '16px' }} 
          />
          <Box style={{ width: '100%', maxWidth: '400px', marginBottom: 5 }}>
            <LinearProgress 
              variant="indeterminate" 
              style={{
                height: 8,
                borderRadius: 4,
                backgroundColor: 'rgba(255,255,255,0.1)',
              }}
            />
          </Box>
          <Typography variant="h6" style={{ 
            marginTop: 8,
            textAlign: 'center',
            animation: 'fadeInOut 2s infinite'
          }}>
            {TIKTOK_LOADING_MESSAGES[loadingMessageIndex]}
          </Typography>
        </Box>
      )}
      
      {!isLoadingTikTokProfile && generatedIdeas.length < 1 && 
        <div className={classes.cardContainer}>
           {new Array(9).fill(0).map((_, index) => (
          <Skeleton variant="rect" width={'25%'} height={200} className={classes.card} style={{backgroundColor: '#ddd'}}/>
           ))}
        </div>
      }
      <Grid container spacing={4} className={classes.cardContainer}>
        {auth?.user && (
          <Masonry
            config={{
              columns: [1, 2, 3],
              gap: [1, 12, 6],
              media: [640, 768, 1024],
            }}
            items={generatedIdeas ?? []}
            render={(item, index) => {
              if (postType === 'linkedin') {
                return <LinkedinCard
                  {...item?.props}
                  onClick={() => handleIdeaClick(item?.props?.post?.text, item?.props?.post?.influencer?.name)}
                />
              }
              else if (postType === 'twitter') {
                return <TwitterCard
                  {...item}
                  onClick={() => handleIdeaClick(item?.content, item?.handle)}
                />
              }
              else if (postType === 'tiktok') {
                if (item.transcript) {
                  item.content = item.transcript.replace(/([.!?])\s+/g, '$1\n')
                }
                if (item.images && item.images.length > 0) {
                  item.content = item.images.map(img => img.text || '').filter(text => text.trim().length > 0).join('\n------\n');
                }
                
                return <TiktokCard
                  post = {item}
                  onClick={() => handleIdeaClick(item.content, item?.author?.nickname)}
                />
              }
            }}
          />
        )}
      </Grid>
      
    </div>
  );
};

export default InspirationDashboard;