import { useEditor, EditorContent, FloatingMenu, BubbleMenu, isTextSelection } from '@tiptap/react'
import CharacterCount from '@tiptap/extension-character-count'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder';
import { Edit as EditIcon, Refresh as RefreshIcon, Send as SendIcon } from '@material-ui/icons';
import { getRandomQuestion } from '../util/chat';
import { useCompletion } from "ai/react";
import { useAuth } from '../util/auth';
import { useCallback, useEffect, useState, useRef } from 'react';
import { 
  Tooltip,
  Paper,
  Typography, 
  makeStyles, 
  Button, 
  TextField, 
  InputAdornment, 
  Box, 
  useMediaQuery, 
  Divider,
  CircularProgress,
  IconButton,
  useTheme 
} from '@material-ui/core';
import { string_to_unicode_variant } from "string-to-unicode-variant";
import { updatePost, createPost } from '../util/db';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { textToHtmlParagraphs, convertBoldToUnicodeVariant } from '../util/util';
import * as Sentry from '@sentry/react';
import { POST_TYPE } from '../util/enums';
import analytics from '../util/analytics';

const charsLimit = 6000

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.tiptap .is-editor-empty:first-child::before': {
      color: '#adb5bd',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
  },
  textEditor: {
    '&:focus': {
      outline: 'none',
    },
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '8px',
    minHeight: 150,
    padding: 10,
    backgroundColor: theme.palette.background.default,
    fontSize: '1rem',
  },
  fullscreen: {
    minHeight: '70vh',
  },
  container: {
    outline: 'black',
    paddingTop: 30,
    position: 'relative'
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: 10,
    backgroundColor: theme.palette.background.default,
  },
  menuButtonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '&::placeholder': {
      color: '#adb5bd', // placeholder color
      opacity: 1,
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  captureButton: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#ffb300', // darken the color slightly on hover
    },
    boxShadow: 'none',
    fontWeight: 'bold',
    borderRadius: 16,
    textTransform: 'capitalize'
  },
  bubbleMenu: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '8px',
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  menuButton: {
    textTransform: 'none',
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
    margin: 3,
    padding: 8,
    color: '#727272',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap',
  },
  bubbleButton: {
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
  },
  bubbleFormatButton: {
    textTransform: 'none',
    width: '20px',
    padding: 0,
    minWidth: '32px',
    margin: 2
  },
  loadingMenu: {
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    opacity: 0.8,
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 9
  },
  characterCount: {
    color: '#868e96',
    marginRight: 'auto'
  },
  exceedLimit: {
    color: 'red'
  },
  editPreviewPaper: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  editPreviewHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    opacity: 0.8
  },
  editPreviewHeaderText: {
    flexGrow: 1,
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  editPreviewCancelButton: {
    color: theme.palette.text.secondary,
    
  },
  editPreviewTextField: {
    marginBottom: theme.spacing(2),
    maxHeight: '350px',
    overflowY: 'auto'
  },
  editPreviewActionArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editPreviewTryAgainButton: {
    textTransform: 'none',
  },
  editPreviewInsertButton: {
    textTransform: 'none',
  },
}));

// define your extension array
const extensions = [
  StarterKit,
  CharacterCount.configure({
    limit: charsLimit,
  }),
  Placeholder.configure({
    placeholder: 'Capture a quick idea',
  }),
  // BubbleMenu.configure({
  //   pluginKey: 'editButtons',
  //   element: document.querySelector('.bubbleMenu'),
  // }),
  // BubbleMenu.configure({
  //   pluginKey: 'editPreview',
  //   element: document.querySelector('.menu-two'),
  // }),
]

const content = null

const PostEditor = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const [editCommand, setEditCommand] = useState(''); // State to store the text input
  const [accessToken, setAccessToken] = useState(null)
  const [lastQuestion, setLastQuestion] = useState(null)
  const [previewContent, setPreviewContent] =useState(null)
  const openPreviewRef = useRef(false)
  const { currentNote, setCurrentNote } = props
  const [streamMode, setStreamMode] = useState(true)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event) => {
    setEditCommand(event.target.value);
  };

  const handleSend = (all) => {
    if (editCommand.trim() !== '') {  // Checks if the editCommand is not just empty spaces
      console.log('Sending command:', editCommand); // Here you can replace console.log with your send logic
      editPost('custom', all, editCommand)
      setEditCommand(''); // Clear the command input after sending
    }
  };

  const {
    complete, completion, input, stop, isLoading, handleInputChange, handleSubmit,
  } = useCompletion({
    api: '/api/completion',
    body: { user: auth?.user },
  });

  console.log('currentNote', currentNote)

  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        class: `${classes.textEditor} ${props.fullscreen ? classes.fullscreen : ''}`,
      },
    },
    content: textToHtmlParagraphs(currentNote?.content),
  })
  const editorRef = useRef(null);

  // if (!editor) {
  //   return null
  // }

  const { empty: selectionIsEmpty, from: selectionFrom, to: selectionTo } = editor ? editor.state.selection : {}
  const selectionContainsText = editor ? editor.state.doc.textBetween(selectionFrom, selectionTo, ' ') : false
  const isDisabled = selectionIsEmpty || !selectionContainsText

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(textToHtmlParagraphs(currentNote?.content))
      editorRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      if (!isMobile) {
        editor.commands.focus('end')
      }
      //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [editor, currentNote])

  const newNote = useCallback(
    async () => {
      editor.commands.clearContent()
      setCurrentNote(null)
    }, [editor, setCurrentNote]
  )

  const queryClient = useQueryClient()

  const editPost = useCallback(
    async (command, all = false, message = null) => {
      analytics.track(`editPost`, { command, type: all? 'post': 'highlight', message })
      let { from, to } = editor.state.selection;
      //if (from !== to) { // Check if something is selected
      // Get the selected text
      const selectedText = all ? editor.getText() : editor.state.doc.textBetween(from, to, "\n\n");
      console.log(selectedText)
      let result = selectedText
      // Process the selected text
      if (command === 'bold') {
        result = string_to_unicode_variant(selectedText, 'bold sans')
      }
      else if (command === 'italic') {
        result = string_to_unicode_variant(selectedText, 'italic sans')
      } else if (command === 'normalize') {
        result = selectedText.normalize('NFKC')
      }
      else {
        const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
        const completion = await complete(selectedText, {
          headers: { Authorization: `Bearer ${token}` },
          body: { task: command, message, fullPost: editor.getText() }
        });
        if (!completion) throw new Error('Failed to get question');
        result = completion

        if (command === 'continue') result = selectedText +'\n' + result
      }
      result = convertBoldToUnicodeVariant(result)
      setPreviewContent({ command: command, all: all, message: message, from: from, to: to, content: textToHtmlParagraphs(result)})
      

      // Replace the selected text with the modified one
      if (all) {
        editor.commands.setContent(textToHtmlParagraphs(result))
      } else {
        // Don't use preview for bold, italic, or normalize.
        if(['bold', 'italic', 'normalize'].includes(command)) {
          editor.chain().focus().deleteRange({ from, to }).insertContent(result).run();
        } else {
          openPreviewRef.current = true
        }
      }
      editor.commands.focus()
      //}
    }
    , [editor, setPreviewContent])

  // Need to rename to savePost
  const saveNote = useCallback(
    async () => {
      const content = editor.getText()

      // Should we retag the post after edit?
      // const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;

      // const completion = await complete(content, {
      //   headers: { Authorization: `Bearer ${token}` },
      //   body: { task: 'tag' }
      // });
      // if (!completion) throw new Error('Failed to get tags');

      // const tags = JSON.parse(completion).categories
      // console.log(tags)

      console.log('Content: ', content)


      let noteId = currentNote?.id
      let noteData;
      if (!noteId) {
        toast.error('Error saving the edit')
        Sentry.captureException(new Error('Error saving the edit'))
      } else {
        noteData = {
          id: noteId,
          content: content,
          status: currentNote.status === 'Scheduled' ? 'Scheduled' : 'Edited',
        }
        await updatePost(noteData)
        queryClient.invalidateQueries({ queryKey: ['posts', auth.user.id] })
        toast.success('Post updated')
      }

      noteData.id = noteId
      setCurrentNote(null)

      return noteData
    },
    [complete, editor, auth, currentNote, setCurrentNote],
  )


  return (
    <div className={classes.container} ref={editorRef}>
      <BubbleMenu 
        editor={editor} pluginKey={'editButtons'} 
        className={classes.bubbleMenu} tippyOptions={{ placement: 'bottom-start' }}
        shouldShow={({
          editor,
          view,
          state,
          from,
          to,
        }) => {
          //taken from bubble menu extension source code
          const { doc, selection } = state
          const { empty } = selection
      
          // Sometime check for `empty` is not enough.
          // Doubleclick an empty paragraph returns a node size of 2.
          // So we check also for an empty text size.
          const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)
      
          // When clicking on a element inside the bubble menu the editor "blur" event
          // is called and the bubble menu item is focussed. In this case we should
          // consider the menu as part of the editor and keep showing the menu
          //const isChildOfMenu = this.element.contains(document.activeElement)
      
          const hasEditorFocus = view.hasFocus() //|| isChildOfMenu
      
          if (openPreviewRef.current || !hasEditorFocus || empty || isEmptyTextBlock || !editor.isEditable) {
            return false
          }
      
          return true
        }}
      >


        <TextField
          fullWidth
          label="Edit with AI"
          placeholder='Make this part ...'
          style={{ fontSize: '1rem', zIndex: 0 }}
          value={editCommand}
          onChange={handleChange}
          variant="outlined"
          size="small"
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleSend(false)} disabled={isLoading}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(event) => { // Allows sending with Enter key
            if (event.key === 'Enter') {
              handleSend();
              event.preventDefault(); // Prevent form submission if in a form
            }
          }}
        />
        {/* <Box style={{display: 'flex', flexDirection: 'row'}}>
          <Button
            size='small'
            variant='outlined'
            onClick={() => editPost('rephrase')}
            disabled={isLoading}
            className={classes.bubbleFormatButton}
          >
            <strong>B</strong>
          </Button>
          <Button
            size='small'
            variant='outlined'
            onClick={() => editPost('rephrase')}
            disabled={isLoading}
            className={classes.bubbleFormatButton}
          >
            <i>I</i>
          </Button>
        </Box> */}
        
        {
          [
            {key: 'rephrase', value: '🔁 Rewrite'},
            {key: 'shorten', value: '✂️ Shorten'},
            {key: 'lengthen', value: '⛓️ Lengthen'},
            {key: 'simplify', value: '💡 Simplify'},
            {key: 'hook-select', value: '🪝 Redo Hook'},
            {key: 'continue', value: '✍️ Continue Writing'},
            {key: 'friendly', value: '😄 More friendly'},
            {key: 'formal', value: '💼 More formal'},
          ].map((item) => (
            <Button
              onClick={() => editPost(item.key)}
              disabled={isLoading}
              className={classes.bubbleButton}
            >
              {item.value}
          </Button>
          ))
        }
        <Button
          onClick={() => editPost('bold')}
          className={classes.bubbleButton}
        >
          <strong>B</strong>&nbsp; Bold
        </Button>
        <Button
          onClick={() => editPost('italic')}
          className={classes.bubbleButton}
        >
          <i>I</i>&nbsp; Italic
        </Button>
        <Button
          // "Normalize" changed to "Remove Formatting" only for UI
          onClick={() => editPost('normalize')}
          className={classes.bubbleButton}
        >
          N&nbsp; Remove Formatting
        </Button>
      </BubbleMenu>
      <BubbleMenu editor={editor}
        pluginKey={'editPreview'}
        className={classes.bubbleMenu}
        tippyOptions={{ placement: 'top-start' }}
        shouldShow={() => { return openPreviewRef.current || false }}
        updateDelay={0}
      >
          <Paper className={classes.editPreviewPaper} elevation={3}>
            <div className={classes.editPreviewHeader}>
              <EditIcon style={{padding: 4, marginRight: 5}} />
              <Typography variant="body2" className={classes.editPreviewHeaderText}>
                {previewContent?.command} {/* Cleve is writing... */}
              </Typography>
              <Button className={classes.editPreviewCancelButton}
                onClick={() => {
                  openPreviewRef.current = false;
                  editor.commands.focus()
                }}
              >
                Cancel
              </Button>
            </div>
            {/* <Typography variant="subtitle2" gutterBottom>
              Rewrite
            </Typography> */}
            {/* <TextField
              multiline
              rows={6}
              variant="outlined"
              fullWidth
              value={previewContent.content}
              // onChange={setPreviewContent}
              className={classes.editPreviewTextField}
            /> */}
            <Typography
                className={classes.editPreviewTextField}
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{ __html: previewContent?.content }}
              />
            <div className={classes.editPreviewActionArea}>
              <Button
                startIcon={<RefreshIcon />}
                onClick={() => {
                  editor.commands.focus();
                  if(previewContent){
                    editPost(previewContent.command ?? 'rephrase', previewContent.all, previewContent.message);
                  }
                }}
                className={classes.editPreviewTryAgainButton}
              >
                Try again
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openPreviewRef.current = false;
                  const {from, to, content } = previewContent
                  editor.chain().focus().deleteRange({ from, to }).insertContent(content).run();
                }}
                className={classes.editPreviewInsertButton}
              >
                Insert
              </Button>
            </div>
          </Paper>
      </BubbleMenu>
      {isLoading && (
        <div className={classes.loadingMenu}>
          <div style={{ margin: 'auto' }}>
            <CircularProgress size={14} /> Loading...
          </div>
        </div>
      )}

      <EditorContent editor={editor} >
      </EditorContent>

      <div className={classes.menuButtonsContainer} >
        <TextField
          fullWidth
          label="How could this post be better?"
          placeholder='Make the tone ...'
          style={{ fontSize: '1rem', zIndex: 0, margin: 5, marginTop: 10 }}
          value={editCommand}
          onChange={handleChange}
          variant="outlined"
          size="small"
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleSend(true)} disabled={isLoading}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(event) => { // Allows sending with Enter key
            if (event.key === 'Enter') {
              handleSend();
              event.preventDefault(); // Prevent form submission if in a form
            }
          }}
        />
        <Button
          onClick={() => {analytics.track('clickUndo'); editor.chain().focus().undo().run()}}
          disabled={editor && !editor.can().undo()}
          className={classes.menuButton}
        >
          ↩️ Undo
        </Button>

        <Button
          onClick={() => {analytics.track('clickRedo'); editor.chain().focus().redo().run()}}
          disabled={editor && !editor.can().redo()}
          className={classes.menuButton}
        >
          ↪️ Redo
        </Button>
        {
          [
            {key: 'rephrase', value: '🔁 Rewrite'},
            {key: 'shorten', value: '✂️ Shorten'},
            {key: 'lengthen', value: '⛓️ Lengthen'},
            {key: 'simplify', value: '💡 Simplify'},
            {key: 'hook', value: '🪝 Redo Hook'},
            {key: 'continue', value: '✍️ Continue Writing'},
            {key: 'emojify', value: '😊 Emojify'},
            {key: 'deemojify', value: '🫥 De-Emojify'},
            {key: 'friendly', value: '😄 More friendly'},
            {key: 'formal', value: '💼 More formal'},
          ].map((item) => (
            <Button
              onClick={() => editPost(item.key, true)}
              disabled={isLoading}
              className={classes.menuButton}
            >
              {item.value}
            </Button>
          ))
        }
        {/* <Button
          onClick={() => editPost('continue', true)}
          disabled={isLoading}
          className={classes.menuButton}
        >
          ✍️ Continue writing
        </Button> */}
        {/* <Button
          onClick={() => setEditCommand('Make the tone more casual')}
          disabled={isLoading}
          className={classes.menuButton}
        >
          Make the tone ...
        </Button> */}
        <Button
          onClick={() => setEditCommand(`Change the language to ${auth?.user?.language?.name || 'Mandarin'}`)}
          disabled={isLoading}
          className={classes.menuButton}
        >
          Change the language to ...
        </Button>
      </div>
      <div className={classes.buttonsContainer}>
        {editor &&
          <Tooltip title={`Word limit for ${currentNote?.type}`} aria-label="Word limit">
            <div className={`${classes.characterCount} ${editor.storage.characterCount.characters() > (POST_TYPE[currentNote?.type]?.limit || 10000) ? classes.exceedLimit : ''}`}>
              {editor.storage.characterCount.characters()}/{POST_TYPE[currentNote?.type]?.limit || 10000} characters
            </div>
          </Tooltip>
        }
        {currentNote?.id &&
          <Button variant="contained" color='default' onClick={() => setCurrentNote(null)} disabled={isLoading} className={classes.captureButton}>
            Cancel
          </Button>
        }
        <Button variant="contained" color='primary' disabled={editor?.isEmpty || isLoading} onClick={() => saveNote()} className={classes.captureButton}>
          {currentNote?.id ? 'Save' : 'Save'}
        </Button>

      </div>
      <Divider />
    </div>
  )
}

export default PostEditor