import { useEditor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/react'

//import FloatingMenu from '@tiptap/extension-floating-menu'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Youtube from '@tiptap/extension-youtube'
import Image from '@tiptap/extension-image'
import FileHandler from '@tiptap-pro/extension-file-handler'
import CharacterCount from '@tiptap/extension-character-count';
import Document from '@tiptap/extension-document'
import { Typography, makeStyles, Box, Tooltip, useMediaQuery, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, SvgIcon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CasinoIcon from '@material-ui/icons/Casino';
import PostAddIcon from '@material-ui/icons/PostAdd';
import BrainstormIcon from '@material-ui/icons/FlashOn';
import SaveIcon from '@material-ui/icons/Save';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import { getRandomQuestion } from '../util/chat';
import { useCompletion } from "ai/react";
import { useAuth } from '../util/auth';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useRouter } from '../util/router';
import { toast } from 'react-toastify';
import RepurposeModal from './RepurposeModal';
import SubscribeModal from './SubcribeModal';
import { apiRequest, convertBoldToUnicodeVariant, textToHtmlParagraphs } from '../util/util';
import analytics from '../util/analytics';
import { POST_TYPE } from '../util/enums';
import {StreakIcon} from '../util/icons';
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Flash24Filled, Chat24Filled, Sparkle24Filled, Mic24Filled, Save24Filled } from '@fluentui/react-icons';

import { createNote, updateNote, usePostMutation, createPost, updateUser, useLikedPosts, updateSaveNoteStat, updateGenPostStat, useLikedPostsByType, search } from '../util/db';
import useDiscardDialog from './DiscardDialog';
import { useQueryClient, useMutation } from 'react-query';
import * as Sentry from '@sentry/react';

import { useWhisper } from '@chengsokdara/use-whisper'
import MicIcon from '@material-ui/icons/Mic';
import Stop from '@material-ui/icons/Stop';
import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
// import Ai from '@tiptap-pro/extension-ai'

const CustomDocument = Document.extend({
  content: 'heading block+',
})


const CHAR_LIMIT = 6000
const AUDIO_LIMIT = 120

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.tiptap h1.is-empty:nth-child(1):first-child::before, .tiptap p.is-empty:nth-child(2):last-child::before': {
      color: '#adb5bd',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
    '.tiptap h1':{
      fontSize: '1.1rem',
    },
    '.tiptap mark': {
      backgroundColor: '#FAF594',
        borderRadius: '0.4rem',
        boxDecorationBreak: 'clone',
        padding: '0.1rem 0.3rem',
    },
    '.tiptap :first-child': {
      marginTop: 0,
    },
    '.tiptap ul, .tiptap ol': {
      padding: '0 1rem',
      margin: '1.25rem 1rem 1.25rem 0.4rem',
    },
    '.tiptap ul li p, .tiptap ol li p': {
      marginTop: '0.25em',
      marginBottom: '0.25em',
    },
    '.tiptap ul[data-type="taskList"]': {
      listStyle: 'none',
      marginLeft: 0,
      padding: 0,
    },
    '.tiptap ul[data-type="taskList"] li': {
      alignItems: 'flex-start',
      display: 'flex',
    },
    '.tiptap ul[data-type="taskList"] li > label': {
      flex: '0 0 auto',
      marginRight: '0.5rem',
      userSelect: 'none',
    },
    '.tiptap ul[data-type="taskList"] li > div': {
      flex: '1 1 auto',
    },
    '.tiptap ul[data-type="taskList"] input[type="checkbox"]': {
      cursor: 'pointer',
    },
    '.tiptap ul[data-type="taskList"] ul[data-type="taskList"]': {
      margin: 0,
    },
    '.tiptap img': {
      display: 'block',
      height: 'auto',
      margin: '1.5rem 0',
      maxWidth: '100%',
    },
    '.tiptap img.ProseMirror-selectednode': {
      outline: `3px solid ${theme.palette.primary.main}`,
    },
    '.MuiButton-outlined':{
      padding: '4px 16px',
    },
    '.MuiButton-root.Mui-disabled svg':{
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  textEditor: {
    '&:focus': {
      outline: 'none',
    },
    fontSize: '1rem',
    minHeight: 150,
    padding: 10,
    backgroundColor: theme.palette.background.default
  },
  fullscreen: {
    minHeight: 100,
    marginBottom: 80
  },
  container: {
    outline: 'black'
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: 10,
    backgroundColor: theme.palette.background.default,
    flexWrap: 'wrap'
  },

  mobileButtonsContainer: {
    position: 'fixed',
    bottom: theme.spacing(1),
    paddingBottom: theme.spacing(7),
    width: '100%',
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '&::placeholder': {
      color: '#adb5bd', // placeholder color
      opacity: 1,
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  captureButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    opacity: 0.8,
    boxShadow: 'none',
    fontWeight: 500,
    // fontFamily: 'Inter',
    borderRadius: 16,
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  micButtonMobile: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'end'
  },
  micButton: {
    
    marginTop: theme.spacing(1),
  },
  floatingMenu: {
    opacity: 0.6,
    zIndex: 99999,
    display: 'block',
  },
  characterCount: {
    color: '#868e96',
    marginRight: '1rem',
    textAlign: 'right'
  },
  imageContainer: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    '&:hover .imageActions': {
      opacity: 1,
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  uploadedImage: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  imageActions: {
    position: 'absolute',
    top: theme.spacing(0.2),
    right: theme.spacing(0.2),
    opacity: 0.7,
    width: '30px',
    height: '30px',
    transition: 'opacity 0.2s',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '50%',
  },
  imageModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    minWidth: '50%',
    maxWidth: '90%',
    maxHeight: '90vh',
  },
  imagesWrapper: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    overflowX: 'auto',
  }
}));


const content = null

const PLACEHOLDERS = [
  "The more I grow up, the more I value friendship quality over quantity.",
  "I started my business because I wanted to build something that I could be proud of.",
  "One thing I learned this week is that small habits can lead to big changes.",
  "I believe in the impact of small acts of kindness.",
  "When you set your mind to do something, the universe conspires to help you.",
  "I just attended an insightful conference about content marketing and I learned that...",
  "People always ask me why I chose to start my business...",
]

const Tiptap = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();
  const auth = useAuth();
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [repurposeModalOpen, setRepurposeModalOpen] = useState(false)
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false)
  const { dialogOpen, confirmNavigation, cancelNavigation } = useDiscardDialog(dirty);
  const [accessToken, setAccessToken] = useState(null)
  const [lastQuestion, setLastQuestion] = useState(null)
  const { currentNote, setCurrentNote, setTabId } = props
  const [images, setImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imagesBase64, setImagesBase64] = useState([]);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);
  const fileInputRef = useRef(null);

  // Update placeholder by using `placeholderRefWrapper.current = 'new placeholder'` and rerender editor
  const placeholderRefWrapper = useRef(PLACEHOLDERS[Math.floor(Math.random() * PLACEHOLDERS.length)]);
  const staticPlaceholderRefWrapper = useRef(({ node }) => {
    if (node.type.name === 'heading') {
      return 'Untitled'
    }
    return placeholderRefWrapper.current;
  });

  // const recorderControls = useAudioRecorder()

  // if (recorderControls?.recordingTime > 90) {
  //   recorderControls.stopRecording()
  // }

  const [recordingTime, setRecordingTime] = useState(0);
  const intervalRef = useRef(null);

  const { data: likedPosts, status: likedPostsStatus } = useLikedPostsByType(auth?.user?.id, Object.keys(POST_TYPE));

  //let likedPosts = useLikedPostsByType(auth?.user?.id, Object.keys(POST_TYPE))

  const editor = useEditor({
    extensions: [
      CustomDocument,
      StarterKit.configure({
        document: false,
      }),
      CharacterCount,
      Link,
      Image,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Youtube,
      Highlight,
      Placeholder.configure({
        showOnlyCurrent: false,
        placeholder: staticPlaceholderRefWrapper.current,
        // placeholder: ({ node }) => {
        //   if (node.type.name === 'heading') {
        //     return 'Untitled'
        //   }

        //   return staticPlaceholderRefWrapper.current()
        // },
      }),
      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files) => {
          files.forEach(file => handleImageUpload(file));
        },
        onPaste: (currentEditor, files, htmlContent) => {
          if (htmlContent) return false;
          files.forEach(file => handleImageUpload(file));
        },
      }),
      // Ai.configure({
      //   appId: process.env.REACT_APP_TIPTAP_AI_APP_ID,
      //   token: process.env.REACT_APP_TIPTAP_AI_TOKEN,
      //   // ATTENTION: This is for demo purposes only
      //   // baseUrl: process.env.REACT_APP_TIPTAP_AI_BASE_URL,
      //   autocompletion: true,
      //   autocompletionOptions: { trigger: 'Tab', accept: 'Tab', debounce: 2000, inputLength: 4000, modelName: 'gpt-4o-mini' }
      // }),
    ],
    editorProps: {
      attributes: {
        class: `${classes.textEditor} ${props.fullscreen ? classes.fullscreen : ''}`,
      },
    },
    content: '<h1></h1><p></p>',
    autofocus: 'start',
    onUpdate({ editor }) {
      // The content has changed.
      setDirty(true);
      
      // Reset auto-save timer on content change
      resetAutoSaveTimer();

      // Restore unsaved content from local storage
      if (!currentNote) localStorage.setItem('content', editor.getHTML());

      // Autoscroll on input
      const { selection } = editor.state;

      if (!selection.empty) {
        return;
      }

      const viewportCoords = editor.view.coordsAtPos(selection.from);
      const absoluteOffset = window.scrollY + viewportCoords.top;

      window.scrollTo(
        window.scrollX,
        absoluteOffset - (window.innerHeight / 2),
      );
    },
  })
  const editorRef = useRef(null);
  

  const {
    complete, completion, input, stop, isLoading, handleInputChange, handleSubmit,
  } = useCompletion({
    api: '/api/completion',
    body: { user: auth?.user },
  });

  


  useEffect(() => {
    if (editor) {
      let {from, to} = editor.state.selection;
      editor.commands.setContent(currentNote?.content || (router.query.title? `<strong>${router.query.title}</strong>` : localStorage.getItem('content')),
        false, 
        {
          preserveWhitespace: "full"
        }
      );
      editor.commands.setTextSelection({from, to});
      editor.commands.focus()
      // Load images if note has them
      if (currentNote?.images) {
        setImages(currentNote.images);
        // Convert URLs back to base64 for OpenAI
        Promise.all(currentNote.images.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        })).then(base64Images => {
          setImagesBase64(base64Images);
        });
      } else {
        setImages([]);
        setImagesBase64([]);
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [editor, currentNote, router])

  const brainstorm = useCallback(
    async (autocomplete = false) => {
      let { from, to } = editor.state.selection;

      if (!editor.isEmpty && (editor.getText().length > 10 && auth.user)) {
        const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
        //const context = await search(auth.user.id, editor.getText())

        const completion = await complete(editor.getText(), {
          headers: { Authorization: `Bearer ${token}` },
          body: { 
            task: 'brainstorm', 
            message: editor.getText(), 
            fullPost: editor.getText(),
            images: imagesBase64 // Use base64 images instead of URLs
          }
        });
        if (!completion) throw new Error('Failed to brainstorm');
        // you should add more validation here to make sure the response is valid
        if(autocomplete){
          placeholderRefWrapper.current = completion;
          editor.commands.focus()
        } else {
          analytics.track('clickBrainstorm')
          editor.chain().focus().insertContent(textToHtmlParagraphs(completion)).run()
        }
        
      }
      

    },
    [complete, editor, auth, imagesBase64],
  );

  const promptQuestion = useCallback(
    async (random = false) => {
      let question = getRandomQuestion(auth?.user?.questions, auth?.user?.topics)

      const lastNode = editor.$doc.lastChild;
      let startPos;
      let endPos = 1

      const replaceQuestion = lastQuestion && ((lastNode && lastNode.textContent === lastQuestion) || (editor.getText()?.trim() === lastQuestion.trim()))

      if (replaceQuestion) {
        const { from, to } = lastNode.range
        editor.commands.deleteRange({ from, to })
        startPos = from
        if (editor.getText()?.trim() == lastQuestion.trim()) {
          editor.commands.clearContent()
          startPos = 0
          endPos = 0
        }
        analytics.track('changeQuestion', { replacedQuestion: lastQuestion })
      }

      const content = editor.getHTML()

      if (!random && !editor.isEmpty && (editor.getText().length > 2 || auth?.user?.questions)) {
        const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
        //const context = await search(auth.user.id, editor.getText())

        const completion = await complete(editor.getText(), {
          headers: { Authorization: `Bearer ${token}` },
          body: { 
            task: editor.isEmpty ? 'personal-question' : 'question',
            images: imagesBase64 // Use base64 images instead of URLs
          }
        });
        if (!completion) throw new Error('Failed to get question');
        // you should add more validation here to make sure the response is valid
        question = completion
        endPos = editor.isEmpty ? 1 : editor.state.doc.content.size

        analytics.track('followUpQuestion')
      }
      const insertPosition = replaceQuestion ? startPos : endPos
      editor.chain().focus().setBold().insertContentAt(insertPosition, question).unsetBold().run()

      analytics.track('clickQuestion')

      setLastQuestion(question)
    },
    [complete, lastQuestion, editor, auth, imagesBase64],
  );

  const newNote = useCallback(
    async () => {
      editor.commands.clearContent()
      setCurrentNote(null)
    }, [editor, setCurrentNote]
  )

  const queryClient = useQueryClient()

  function getTitle(editor) {
    const json = editor.getJSON()
    const firstNode = json.content[0]
    
    if (firstNode && firstNode.type === 'heading' && firstNode.attrs.level === 1) {
      return firstNode.content?.[0]?.text || ''
    }
    
    return '' // Return empty string if first node is not h1
  }

  const saveNote = useCallback(
    async (options = {}) => {
      const content = editor.getHTML()

      let noteId = currentNote?.id
      let tags = currentNote?.tags
      let completion = null
      setSaving(true)

      // const toastId = toast.loading("Saving note...")
      try {
        // tag note
        // if (!noteId) {
        //   const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
        //   completion = await complete(content, {
        //     headers: { Authorization: `Bearer ${token}` },
        //     body: { task: 'tag' }
        //   });
        //   if (!completion) Sentry.captureMessage('Failed to get tags');
        //   console.log(completion)

        //   let parsedResponse = null
        //   try {
        //     parsedResponse = JSON.parse(completion)
        //   } catch (error) {
        //     console.log(error)
        //     Sentry.captureException(new Error('Failed to parse tags'), { extra: { error, parsedResponse } })
        //   }
        //   tags = parsedResponse?.categories || parsedResponse
        // }

        const title = getTitle(editor)

        let noteData = {
          title: title,
          content: content,
          status: options.status || 'Idea',
          tags: tags || [],
          owner: auth.user.id,
          images: images, // Add images array to note data
          ...options
        }

        

        if (!noteId) {
          noteId = await createNote(noteData)
          await updateSaveNoteStat(auth.user)
          
          //alert('Note saved')
        } else {
          noteData.id = noteId
          await updateNote(noteData)
          //alert('Note updated')
        }

        if (content.length > 100) {
          await apiRequest('jobs', 'POST', {
            name: "post-process-note",
            data: {
              noteId: noteId,
              note: noteData
          }
        });
        }

        //Update user tags if there is new tags
        // const globalTags = auth.user?.tags || []
        // const newTags = tags.filter(tag => !globalTags.includes(tag))
        // if (newTags.length > 0) {
        //   await updateUser(auth.user.id, { tags: [...new Set([...globalTags, ...tags])] })
        // }

        // toast.update(toastId, { render: currentNote?.id ? "Note Updated" : "Note saved", type: "success", isLoading: false, autoClose: 1 });
        queryClient.invalidateQueries({ queryKey: ['notes', auth.user.id] })

        noteData.id = noteId
        setCurrentNote(noteData)
        setDirty(false)
        setSaving(false)
        localStorage.removeItem('content')

        analytics.track('saveNote', {
          content: editor.getText(),
          noteId: noteId,
          words: editor.storage.characterCount.words()
        })

        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ['notes', auth.user.id] })
        }, 3000)
        return noteData
      } catch (error) {
        console.log(error)
        Sentry.captureException(new Error('Failed to save note'), { extra: { error } })
        // toast.update(toastId, { render: "Failed to save note", type: "error", isLoading: false, autoClose: 2000 });
        toast.error("Failed to save note")
      }
    },
    [complete, editor, auth, currentNote, setCurrentNote, images], // Add images to dependency array
  )

  const sentToChatGPT = async (audioBlob) => {
    console.log(audioBlob)
    const formData = new FormData();
    formData.append('file', audioBlob, { type: 'audio/wav' });
    formData.append('model', 'whisper-1');
    formData.append('user', JSON.stringify(auth.user));
    // formData.append('token', previewToken);
    // formData.append('language', inputLanguage);

    try {
      const res = await fetch('/api/whisper', {
        method: 'POST',
        body: formData,
      });

      const { text, error } = await res.json();
      if (text) {
        //const args = { id: id, content: text, role: 'user' };
        //await append(args);
        editor.commands.insertContent(text)
      } else {
        console.error('Error from whisper response', error);
      }
    } catch (error) {
      console.error('In voice-recorder component', error.message);
    }
  };

  const { startRecording, stopRecording, recording, transcribing, speaking } = useWhisper({
    onTranscribe: sentToChatGPT,
    removeSilence: true,
  })

  const checkCharLimit = (showToast = true) => {
    // check whether the note character count exceeds the limit and trigger a toast message if so and return false
    if (editor.storage.characterCount.characters() > CHAR_LIMIT) {
      if (showToast) {
        toast.error('Oops! Your note exceeds the character limit!')
      }
      return false
    }
    return true
  }

  const handleSave = async () => {
    //If statement to check if note character exceeded the limit(6000 character)
    if (checkCharLimit()) {
      //If character count below limit, proceed to save note
      await saveNote()
      analytics.track('clickSave')
      if (router.pathname === '/notes/new') {
        router.push('/')
      }
    }
  }

  const createPostMutation = useMutation({
    mutationFn: createPost,
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['posts'] })
      }, 3000)
      //router.push(`/notes/${currentNote.id}/?tab=posts`)
    },
    onError: (error) => {
      console.error(error)
      toast.error('Failed to generate post')
    }
  })

  const handleGenerate = () => {
    if (auth?.user?.credit < 1) {
      setSubscribeModalOpen(true)
      toast.error("Oops! You've made too many posts. Wanna keep going? Subscribe now while in beta for biggest discount!", { autoClose: 5000 })
      return
    } else if (!checkCharLimit()) {
      return
    }
    setRepurposeModalOpen(true)
  }

  const generatePost = useCallback(
    async (platforms, options={}) => {
      const content = editor.getHTML()
      setRepurposeModalOpen(false)
      const toastId = toast.loading("Generating post...")
      setLoading(true)

      const [context, noteData] = await Promise.all([
        search(auth.user.id, editor.getText(), 0),
        saveNote({ status: 'Generated' })
      ]);

      try {
        const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;

        const generatePromises = Object.entries(platforms).filter(([_, platformData]) => platformData.checked)
          .map(async ([platform, platformData]) => {
            const body = {
              task: platform,
              likedPosts: likedPosts ? likedPosts[platform] : [],
              context: { ids: context ? context.ids.filter(item => item !== noteData.id) : [] },
              options: options,
              ...(options.useImages && { images: imagesBase64 })
            };

            const completion = await complete(editor.getText(), {
              headers: { Authorization: `Bearer ${token}` },
              body: body
            });
            if (!completion) throw new Error('Failed to generate post');

            await apiRequest("credit", "POST", {});

            await createPostMutation.mutateAsync({
              noteId: noteData.id,
              content: convertBoldToUnicodeVariant(completion),
              status: 'Draft',
              owner: auth.user.id,
              type: platform,
              tags: noteData.tags
            });

            analytics.track('generatePost', {
              noteId: noteData.id,
              content: completion,
              type: platform
            });

          queryClient.invalidateQueries({ queryKey: ['posts'] });
          setTabId(1);

          return platform;
        });

        // Generate posts for all selected platform in parallel
        await Promise.all(generatePromises);
        await updateGenPostStat(auth.user, generatePromises.length)

        setLoading(false)
        analytics.track('clickGenerate', {
          noteId: noteData.id,
        })
        toast.update(toastId, { render: "Post Generated", type: "success", isLoading: false, autoClose: 2000 });
        //setTabId(1)
        router.replace(`/notes/${noteData.id}/?tab=posts`)
      } catch (error) {
        toast.update(toastId, { render: "Failed to generate post", type: "error", isLoading: false, autoClose: 2000 });
        Sentry.captureException(new Error('Failed to generate post'), { extra: { error } })
      }

    },
    [complete, editor, auth, currentNote, imagesBase64],
  )

  useEffect(() => {
    if (recording) {
      intervalRef.current = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current)
      setRecordingTime(0)
    }

    return () => clearInterval(intervalRef.current)
  }, [recording])

  useEffect(() => {
    if (recordingTime > AUDIO_LIMIT) {
      toast.error(`You've reached the maximum recording time (${AUDIO_LIMIT}s).`)
      stopRecording()
    }
  }, [recordingTime])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`
  }

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const image = document.createElement('img');
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calculate new dimensions while maintaining aspect ratio
          let width = image.width;
          let height = image.height;
          const maxDim = 1200;

          if (width > height && width > maxDim) {
            height *= maxDim / width;
            width = maxDim;
          } else if (height > maxDim) {
            width *= maxDim / height;
            height = maxDim;
          }

          canvas.width = width;
          canvas.height = height;

          // Draw and compress image
          ctx.drawImage(image, 0, 0, width, height);
          
          // Get compressed base64 image (0.8 = 80% quality)
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.8);
          resolve(compressedBase64);
        };
      };
    });
  };

  const handleImageUpload = async (file) => {
    try {
      setUploadingImage(true);
      let base64;
      
      // Check file size and compress if needed (1MB = 1048576 bytes)
      if (file.size > 1048576) {
        toast.info('Compressing image...', { autoClose: 2000 });
        base64 = await compressImage(file);
      } else {
        // If file is small enough, just convert to base64 without compression
        base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      }

      //toast.info('Uploading image...', { autoClose: 2000 });
      const response = await fetch('/api/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          image: base64,
          userId: auth?.user?.id
        }),
      });
      
      const data = await response.json();
      if (data.url) {
        setImages(prev => [...prev, data.url]);
        setImagesBase64(prev => [...prev, base64]);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setUploadingImage(false);
    }
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveImageIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveImageIndex(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleRemoveImage = async () => {
    try {
      const imageUrl = images[activeImageIndex];
      
      // Delete from Firebase Storage
      console.log('deleting image', imageUrl)
      if (currentNote?.id) {
        const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
        await fetch('/api/upload', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ 
            imageUrl,
            userId: auth?.user?.id
          }),
        });
      }

      console.log('deleting image from note')
      // Update state
      
    } catch (error) {
      console.error('Error deleting image:', error);
      //toast.error('Failed to delete image');
    }
    setImages(prev => prev.filter((_, index) => index !== activeImageIndex));
      setImagesBase64(prev => prev.filter((_, index) => index !== activeImageIndex));
      
      // If note exists, update it without the deleted image
      if (currentNote?.id) {
        const updatedImages = images.filter((_, index) => index !== activeImageIndex);
        await updateNote({
          ...currentNote,
          images: updatedImages,
        });
      }
    handleMenuClose();
  };

  const handleDownloadImage = () => {
    const image = images[activeImageIndex];
    const link = document.createElement('a');
    link.href = image;
    link.download = `image-${Date.now()}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleMenuClose();
  };

  const handleAddMenuClick = (event) => {
    setAddMenuAnchorEl(event.currentTarget);
  };

  const handleAddMenuClose = () => {
    setAddMenuAnchorEl(null);
  };

  const handleAddImageClick = () => {
    fileInputRef.current.click();
    handleAddMenuClose();
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    files.forEach(handleImageUpload);
    event.target.value = null; // Reset input
  };

  // Add this near other state declarations
  const autoSaveTimerRef = useRef(null);

  // Move resetAutoSaveTimer here, after editor is defined
  const resetAutoSaveTimer = useCallback(() => {
    if (autoSaveTimerRef.current) {
      clearTimeout(autoSaveTimerRef.current);
    }

    autoSaveTimerRef.current = setTimeout(async () => {
      if (dirty && editor && !editor.isEmpty) {
        try {
          await saveNote();
          // toast.success("Auto-saved", {
          //   autoClose: 2000,
          //   hideProgressBar: true,
          //   position: "bottom-right"
          // });
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    }, 5000);
  }, [dirty, editor, saveNote]);

  // Modify the useEffect to use resetAutoSaveTimer
  useEffect(() => {
    if (dirty) {
      resetAutoSaveTimer();
    }

    return () => {
      if (autoSaveTimerRef.current) {
        clearTimeout(autoSaveTimerRef.current);
      }
    };
  }, [dirty, resetAutoSaveTimer]);

  return (
    <div className={classes.container} ref={editorRef}>
      {props.buttonMode ?
        <Box padding={2} paddingBottom={10}>
          <Typography color={'textSecondary'}>Capture a quick idea</Typography>
        </Box>

        :
        <div>
          <div className={classes.imagesWrapper}>
        {uploadingImage && (
          <Box display="flex" alignItems="center" mb={2} p={2} bgcolor="rgba(0, 0, 0, 0.05)" borderRadius={1}>
            <CircularProgress size={20} style={{ marginRight: 8 }} />
            <Typography variant="body2">Processing image...</Typography>
          </Box>
        )}
        {images.map((image, index) => (
          <div key={index} className={classes.imageContainer}>
            <img
              src={image}
              alt={`Uploaded ${index + 1}`}
              className={classes.uploadedImage}
              onClick={() => handleImageClick(image)}
            />
            <IconButton
              className={`imageActions ${classes.imageActions}`}
              onClick={(e) => handleMenuOpen(e, index)}
            >
              <MoreHorizIcon />
            </IconButton>
          </div>
        ))}
      </div>
          
          {/* {editor &&
              <FloatingMenu editor={editor} 
                tippyOptions={{ placement: 'right' }} 
                className={classes.floatingMenu}
                shouldShow={({ editor, view, state }) => {
                  const { selection } = state
                  const { $anchor, empty } = selection
                  const isRootDepth = $anchor.depth === 1
                  const isEmptyTextBlock = $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && !$anchor.parent.textContent
              
                  if (
                    !view.hasFocus()
                    || editor.getText().length < 10
                    || !empty
                    || !isRootDepth
                    || !isEmptyTextBlock
                    || !editor.isEditable
                  ) {
                    return false
                  }
              
                  return true
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  className={classes.button}
                  style={{zIndex: 1000}}
                  startIcon={<BrainstormIcon />}
                  onClick={() => brainstorm()}
                >
                  {isLoading? <CircularProgress size={20}/> : 'Write with AI'}
                </Button>
              </FloatingMenu>
          } */}
          <EditorContent editor={editor} />
        </div>
      }



      <div>
        {/* <Divider /> */}
        <div>
        <Tooltip title="Write with AI">
            {/* <IconButton className={classes.iconButton} aria-label="prompt question" onClick={() => promptQuestion()} disabled={isLoading || props.buttonMode}>
              {isLoading? <CircularProgress size={20}/> : <QuestionAnswerIcon />}
            </IconButton> */}
            <Button variant="outlined" id="brainstormButton"
              // color='secondary'
              disabled={(editor && editor.getText().length < 10) || isLoading || loading || props.buttonMode} onClick={() => brainstorm()}
              className={classes.captureButton}
              startIcon={<SvgIcon color="primary" style={{padding: '2px', marginRight: '0px'}}><Flash24Filled/></SvgIcon>}
            >
              Brainstorm
            </Button>
          </Tooltip>

          <Tooltip title="Get personalised question">
            {/* <IconButton className={classes.iconButton} aria-label="prompt question" onClick={() => promptQuestion()} disabled={isLoading || props.buttonMode}>
              {isLoading? <CircularProgress size={20}/> : <QuestionAnswerIcon />}
            </IconButton> */}
            <Button variant="outlined" id="askMeButton"
              // color='secondary'
              disabled={isLoading || loading || props.buttonMode} onClick={() => promptQuestion()}
              className={classes.captureButton}
              startIcon={<SvgIcon style={{color: '#2990FF', padding: '2px'}}><Chat24Filled/></SvgIcon>}
            >
              {isLoading || loading ? <CircularProgress size={20} /> : "Ask me"}
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            disabled={transcribing || props.buttonMode}
            onClick={recording ? () => stopRecording() : () => { analytics.track('clickTranscribe'); startRecording() }}
            className={classes.captureButton}
            style={{minWidth: '34px', minHeight: '34px', padding: '4px', paddingRight: recording ? '14px' : '5px', borderRadius: '18px'}}
          >
            {recording ? <SvgIcon color="error" style={{padding: '2px'}}><Mic24Filled/></SvgIcon> : <SvgIcon style={{padding: '2px'}}><Mic24Filled/></SvgIcon>}
            {recording && formatTime(recordingTime)}
            {transcribing ? <CircularProgress size={20} /> : ""}
          </Button>

          <Button
            variant="outlined"
            className={classes.captureButton}
            onClick={handleAddMenuClick}
            style={{minWidth: '34px', minHeight: '34px', padding: '4px', borderRadius: '18px'}}
          >
            <AddIcon />
          </Button>

          <Menu
            anchorEl={addMenuAnchorEl}
            keepMounted
            open={Boolean(addMenuAnchorEl)}
            onClose={handleAddMenuClose}
          >
            <MenuItem onClick={handleAddImageClick}>
              <AddPhotoAlternateIcon fontSize="small" style={{ marginRight: 8 }} />
              Add Image
            </MenuItem>
          </Menu>

          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
        </div>
        <div className={`${classes.buttonsContainer} `}>
          {/* <Typography variant="subtitle1" color="textSecondary" style={{marginBottom: 0, marginRight: 'auto', fontSize: '0.8rem'}}>
            {!currentNote || dirty? 'Unsaved' : 'Saved'}
          </Typography> */}

          {/* <Box className={isMobile ? classes.micButtonMobile : classes.micButton}>

            <AudioRecorder
              recorderControls={recorderControls}
              onRecordingComplete={(audioBlob) => sentToChatGPT(audioBlob)}
              showVisualizer

            />
          </Box> */}

          
          
          {/* <Button variant="contained" color='default' onClick={() => ()} disabled={isLoading} className={classes.captureButton}>
              New Note
            </Button> */}
          {/* {currentNote?.id && 
            <Button variant="contained" color='default' onClick={() => newNote()} disabled={isLoading} className={classes.captureButton}>
              New Note
          </Button>
          } */}
          <Button
            // variant="outlined"
            // color='secondary'
            disabled={editor?.isEmpty || isLoading || loading || !dirty}
            onClick={handleSave}
            className={classes.captureButton}
            // startIcon={<SvgIcon><Save24Filled/></SvgIcon>}
          >
            {dirty ? saving ? 'Saving...' : 'Save as draft' : 'Saved'}
          </Button>
          <Tooltip title="Generate post">
            <span>
          <Button
                id="generateButton"
                variant="contained"
                color='primary' disabled={editor?.isEmpty || isLoading || loading}
                onClick={handleGenerate}
            className={classes.captureButton}
                startIcon={<SvgIcon><Sparkle24Filled/></SvgIcon>}
          >
                Generate
          </Button>
            </span>
          </Tooltip>
        </div>
        {editor && editor.storage.characterCount.characters() > (CHAR_LIMIT - 1000) &&
          <div className={classes.characterCount}>
            {/* change note: added styling for text to change color (red) if exceeded limit */}
            <span style={{ color: editor.storage.characterCount.characters() > CHAR_LIMIT ? "red" : "" }}>{editor.storage.characterCount.characters()}/{CHAR_LIMIT} characters </span>
            <br />
            {editor.storage.characterCount.words()} words
          </div>
        }
      </div>
      <SubscribeModal open={subscribeModalOpen} onClose={() => setSubscribeModalOpen(false)} />
      <RepurposeModal 
        open={repurposeModalOpen} 
        onClose={() => setRepurposeModalOpen(false)} 
        handleGenerate={generatePost}
        hasImages={images.length > 0}
      />
      <Dialog
        open={dialogOpen}
        onClose={cancelNavigation}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">Discard changes?</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            If you go back now, you will lose any changes that you've made.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmNavigation} style={{ color: '#FF0033', fontWeight: 'bold' }} >
            Discard
          </Button>
          <Button onClick={cancelNavigation} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* <div>
          <Box className={`menu1 ${classes.floatingMenu}`}>
          <Button
            variant="outlined"
              color="secondary"
              size="small"
              className={classes.button}
              startIcon={<QuestionAnswerIcon />}
              onClick={() => promptQuestion()}
            >
              {isLoading? <CircularProgress size={20}/> : 'Ask me a question'}
          </Button>
          </Box>
        </div> */}
      

      {/* Image actions menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDownloadImage}>
          <GetAppIcon fontSize="small" style={{ marginRight: 8 }} />
          Download
        </MenuItem>
        <MenuItem onClick={handleRemoveImage}>
          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
          Remove
        </MenuItem>
      </Menu>

      {/* Full size image modal */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className={classes.imageModal}
      >
        <img
          src={selectedImage}
          alt="Full size"
          className={classes.modalImage}
          onClick={() => setModalOpen(false)}
        />
      </Modal>
    </div>
  )
}

export default Tiptap